.error-boundary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & > p {
    width: 100%;
    color: #555;
    font-size: 20px;
    text-align: center;
    height: 200px;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
