@use '../../assets/scss/utilities' as *;

.footer {
  @include mq(768px, max) {
    height: 73px;
  }
  .footer-mobile {
    @include mq(768px, min) {
      display: none;
    }
    box-shadow: 1px -3px 20px #00000012;
    padding: 25px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: -1px;
    left: 0;
    right: 0;
    background: #ffffff;
    z-index: 10;
    a {
      width: 25px;
      text-align: center;
      &.active {
        img {
          // filter: brightness(0) saturate(100%) invert(0%) sepia(0%) hue-rotate(0deg) brightness(0%)
          //   contrast(0%);
          // filter: invert(13%) sepia(87%) saturate(2022%) hue-rotate(300deg) brightness(85%)
          //   contrast(110%);
        }
      }
    }
    & > .basket {
      position: relative;
      & > .basket-count {
        position: absolute;
        top: -3px;
        right: -5px;
        background: #3e3e3e;
        color: #ffffff;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 8px;
      }
    }
  }
  .footer-content {
    background: white;
    padding: 66px 0 60px;
    @include mq(768px, max) {
      display: none;
    }
    .footer-content-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 90px;
      @include mq(1250, max) {
        gap: 130px;
      }
      .footer-left {
        .logo {
          position: relative;
          right: 14px;

          width: 172px;
          height: 60px;
          max-width: 172px;
          max-height: 60px;
          bottom: 8px;
        }
        .social-media {
          .title {
            font-weight: bold;
            font-size: 22px;
            line-height: 24px;
            color: #303030;
            text-transform: uppercase;
            font-family: 'Modern20' !important;
            margin: 10px 0 15px;
          }
          .description {
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: black;
            text-transform: none;
            margin: 10px 0 15px;
          }
          .social-media-wrapper {
            display: flex;
            align-items: center;
            gap: 11px;
            justify-content: center;
          }
        }
      }
      .footer-information {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;

        @include mq(932px, max) {
          gap: 20px;
          height: 320px;
        }
        .footer-information-link {
          flex: 0.5;
          .title {
            font-weight: bold;
            font-size: 20px;
            font-family: 'Modern20' !important;
            line-height: 18px;
            color: #303030;
            // text-transform: uppercase;
            padding-bottom: 40px;
            &:last-child {
              // Target the last .title element
              padding-bottom: 0px; // Set padding-bottom to 0 for the last .title element
            }
          }
          .description {
            font-size: 16px;

            color: #020202;
            // text-transform: uppercase;
            padding-bottom: 15px;
          }

          .all-links {
            display: flex;
            flex-direction: column;
            gap: 20px;
            @include mq(768px, max) {
              display: flex;
              flex-direction: column;
              padding: 10px;
              gap: 10px;
              color: black;
            }
            .link {
              font-size: 13px;
              font-weight: 500;
              color: #303030;
              &:hover {
                color: #b0903d;
              }
            }
          }
        }
        .footer-information-link:last-child {
          flex: 1;
          .title {
            font-weight: bold;
            font-size: 20px;
            font-family: 'Modern20' !important;
            line-height: 18px;
            color: #303030;

            // text-transform: uppercase;
            padding-bottom: 20px;
          }
          .description {
            font-size: 16px;

            color: #020202;
            // text-transform: uppercase;
            padding-bottom: 15px;
          }
          & .login-container {
            display: flex;
            justify-content: space-between;
            width: 49%;
            height: 3rem;
            background-color: white;

            position: relative;
            & .mail-input {
              min-width: 280px;
              padding: 10px;
              &::placeholder {
                font-size: 1rem;
                color: #bbbbbbbb;
                padding: 10px;
              }
            }
            & .login-btn {
              background-color: black;
              min-width: 130px;
              color: white;

              font-weight: 200;
              font-size: 1.4;
              border: 1px solid #020202;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
            }
            & > .error-wrapper {
              position: absolute;
              z-index: 0;
              transition: 0.3s ease;
              color: white;
              top: 0%;
            }
            & > .newsletter-error-msg {
              z-index: 5;
              top: 120%;
            }
          }
          & > .error-wrapper {
            position: absolute;
            z-index: 0;
            transition: 0.3s ease;
            color: white;
            top: 0%;
          }
          & > .newsletter-error-msg {
            z-index: 5;
            top: 120%;
          }
          .all-links {
            display: flex;
            flex-direction: column;
            gap: 20px;
            @include mq(768px, max) {
              display: flex;
              flex-direction: column;
              padding: 10px;
              gap: 10px;
              color: black;
            }
            .link {
              font-size: 13px;
              font-weight: 500;
              color: #303030;
              &:hover {
                color: #b0903d;
              }
            }
          }
        }
      }
    }
  }
  .footer-content-mobile {
    display: none;
    @include mq(768px, max) {
      display: block;
      background: white;
      padding: 10px;
    }
    .footer-content-mobile-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 90px;

      @include mq(932px, max) {
        gap: 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .footer-left {
        .logo {
          position: relative;
          right: 14px;
          width: 172px;
          height: 60px;
          max-width: 172px;
          max-height: 60px;
          bottom: 8px;
        }
        .social-media {
          .title {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #303030;
            text-transform: uppercase;
            margin: 25px 0 15px;
          }
          .social-media-wrapper {
            display: flex;
            align-items: center;
            gap: 11px;
            justify-content: center;
          }
        }
      }
      .footer-information-mobile {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        flex: 1;
        @include mq(932px, max) {
          gap: 20px;
        }
        .footer-information-link {
          flex: 1;
          .title {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #303030;
            // text-transform: uppercase;
            padding-bottom: 15px;
          }
          .all-links {
            display: flex;
            flex-direction: column;
            gap: 20px;
            @include mq(768px, max) {
              display: flex;
              flex-direction: column;
              padding: 10px;
              gap: 10px;
              color: black;
            }
            .link {
              font-size: 12px;
              font-weight: 500;
              color: #303030;
              &:hover {
                color: #b0903d;
              }
            }
          }
        }
      }
    }
  }

  .media-container {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    margin-top: 6%;

    & .paragraphe {
      font-size: 1rem;
    }

    & .icon-wrapper {
      width: 25px;
      height: 25px;
      padding: 0.6;
      cursor: pointer;
      background-color: #484848;
      border: 1px solid #020202;

      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .footer-copyright {
    @include mq(768px, max) {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 15px;
    p {
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      color: #303030;
    }
  }
}

.footer-social-icons {
  width: 27px;
}
