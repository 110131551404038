@use './../../../assets/scss/utilities/mixins' as *;

.modal-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
  .menu-modal-top {
    width: 420px;

    @include mq(415px, max) {
      width: 100vw;
    }
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 19px 7px 32px;
      margin-bottom: 40px;
      .modal-title {
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        color: white;
        font-family: Modern20;
      }
      .close {
        width: 20px;
        height: 20px;
      }
    }
    .modal-content {
      padding: 15px 0px 15px 0;

      .modal-content__top {
        background-color: black;
      }
    }
  }

  .menu-modal-bottom {
    background: #ebebeb;
    padding: 16px 19px 19px 32px;
    .menu-bottom__wrapper {
      padding-right: 20px;
    }
  }
}
