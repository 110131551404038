@use './assets/scss/utilities' as *;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat';
  // user-select: none;
  // -webkit-user-drag: none;
  // border: 1px solid red;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3 {
  font-family: 'Modern20';
  font-weight: 600;
}
h4 {
  font-family: 'Modern20';
  font-weight: 500;
}

body {
  background-color: white;
  overflow-x: hidden;
  font-family: 'Modern20';
 }

html {
  scroll-behavior: smooth;
}
.container {
  // @include mq(1921px, min) {
  //   max-width: 1900px;
  //   width: 100%;
  // }
  // @include mq(1921px, max) {
  //   width: 85%;
  // }
  width: 85%;
  padding: 0 20px;
  margin: 0 auto;
  @include mq(1200px, max) {
    width: 95%;
  }
  @include mq(768px, max) {
    width: 100%;
  }
}
.container-product {
  // @include mq(1921px, min) {
  //   max-width: 1900px;
  //   width: 100%;
  // }
  // @include mq(1921px, max) {
  //   width: 85%;
  // }
  width: 80%;
  padding: 0 20px;
  margin: 0 auto;
  @include mq(1200px, max) {
    width: 95%;
  }
  @include mq(768px, max) {
    width: 100%;
  }
}

.containerShopping {
  // @include mq(1921px, min) {
  //   max-width: 1900px;
  //   width: 100%;
  // }
  // @include mq(1921px, max) {
  //   width: 85%;
  // }
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  @include mq(1200px, max) {
    width: 95%;
  }
  @include mq(768px, max) {
    width: 100%;
  }
}
.container-hashtag {
  // @include mq(1921px, min) {
  //   max-width: 1900px;
  //   width: 100%;
  // }
  // @include mq(1921px, max) {
  //   width: 85%;
  // }
  width: 100%;
  padding: 0px 0px;
  margin: 0 auto;
  @include mq(1200px, max) {
    width: 95%;
  }
  @include mq(768px, max) {
    width: 100%;
  }
}
.footer-information {
  height: auto;
  margin-bottom: 100px; // Set minimum height
  // ... other styles ...
}
.container2 {
  // @include mq(1921px, min) {
  //   max-width: 1900px;
  //   width: 100%;
  // }
  // @include mq(1921px, max) {
  //   width: 92.5%;
  // }
  width: 92.5%;
  padding-left: 20px;
  margin-left: auto;
  padding-right: 150px;
  @include mq(1200px, max) {
    width: 97.5%;
    padding-right: 10px;
  }
  @include mq(768px, max) {
    width: 100%;
    padding-right: 10px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-width: 4px;
  scrollbar-color: #3e3e3e #838282;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  z-index: 120;
}

*::-webkit-scrollbar-track {
  background: #838282;
}

*::-webkit-scrollbar-thumb {
  background-color: #3e3e3e;
  border-radius: 0;
  border: 0px solid #838282;
}

.loading {
  @include loading-skeleton-radial(100%, 100%, 0px, #b3b1b1, #ebebeb, 2s);
}
