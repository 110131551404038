@use './../../../assets/scss/utilities/mixins' as *;

.search-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  .search-modal-top {
    padding: 44px 27px 35px 35px;
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        position: relative;
        left: 8px;
      }
      .modal-title {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        color: #2c2c2c;
      }
    }
    .modal-content {
      padding: 20px 20px 0 0;
      input[type='search'] {
        appearance: none;
        width: 100%;
        height: 45px;
        outline: none;
        border: none;
        border: 1px solid #d9d9d9;
        padding-left: 26px;
        &::placeholder {
          font-size: 13px;
          line-height: 16px;
          color: #ccc3c3;
          font-style: italic;
        }

        &::-webkit-search-cancel-button {
          position: relative;
          right: 27px;
          -webkit-appearance: none;
          height: 18px;
          width: 18px;
          border-radius: 10px;
          background: url('./../../../assets/img/icons/close.svg');
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          @include mq(768px, max) {
            all: unset;
          }
        }
      }
      .search-result__cards {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin: 20px 0;
        height: calc(100vh - 215px);
        overflow-y: auto;
        @include mq(768px, max) {
          gap: 13px;
        }
        & .voir-tous {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
