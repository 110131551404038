@use '../../assets/scss/utilities' as *;

.product-basket-card {
  display: flex;
  justify-content: space-between;
  &.loading {
    @include loading-skeleton-radial(100%, 100%, 5px, #b3b1b1, #ebebeb, 2s);
  }
  .product-basket-card-wrapper {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    @include mq(768px, max) {
      gap: 8px;
    }
    .product-image {
      width: 79px;
      height: 85px;
      object-fit: cover;

      @include mq(768px, max) {
        width: 71px;
        height: 76px;
      }
    }
    .quantity {
      font-size: 14px;
      line-height: 18px;
      color: #2c2c2c;
      padding-left: 10px;
    }
    & > .product-content {
      & > .title {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #3e3e3e;
        padding-bottom: 5px;
        @include mq(768px, max) {
          font-size: 12px;
          line-height: 15px;
        }
      }
      & > .description {
        font-size: 13px;
        line-height: 15px;
        color: #3e3e3e;
        font-weight: 500;
        padding: 2px 0 7px;
        padding-bottom: 30px;

        @include mq(768px, max) {
          font-size: 14px;
          line-height: 18px;
          padding-bottom: 15px;
        }
      }
      & > .option {
        line-height: 18px;
        color: #2c2c2c;
        padding-bottom: 5px;
        & > .option-name {
          font-size: 12px;
        }
        & > .option-value {
          font-size: 12px;
          font-weight: 500;
        }
      }
      & > .price {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #3e3e3e;
      }
      & > .quantity {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 125px;
        height: 28px;
        background: #ffffff;
        border: 1px solid #707070;
        padding: 0 12px;
        .decremental,
        .incremental {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 28px;
          color: #303030;
          font-size: 24px;
          line-height: 29px;
        }
        & > p {
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: #303030;
        }
      }
    }
  }

  .trash-icon {
    font-size: 0.8rem;
    margin-top: 15%;
    color: #b0903d;
    text-decoration: underline;
    cursor: pointer;
  }
}
