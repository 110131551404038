@use '../../../assets/scss/utilities' as *;

.reset-password-wrapper {
  padding: 32px 46px;
  position: relative;
  width: var(--modalWidth);
  min-height: var(--modalHeight);
  max-height: 95vh;
  @include mq(786px, max) {
    padding: 37px 18.5px;
    width: 90vw;
    max-height: 90vh;
  }

  & > .close {
    position: absolute;
    top: 5px;
    right: 9px;
  }

  & > .modal-content {
    & > .success-message {
      font: normal normal 600 14px/21px Montserrat;
      color: #2daf54;
    }
    & > .error-message {
      font: normal normal 600 14px/21px Montserrat;
      color: #cc0d0d;
    }
    & > .message-text {
      font: normal normal normal 12px/21px Montserrat;
      color: #3e3e3e;
      margin-top: 12px;
    }
  }
}
