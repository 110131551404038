@use './../../../assets/scss/utilities/mixins' as *;

.filter-modal-wrapper {
  width: 374px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @include mq(415px, max) {
    width: 100vw;
  }
  .filter-modal {
    padding: 44px 27px 35px 35px;
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 45px;
      .close {
        position: relative;
        left: 8px;
      }
      .modal-title {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        color: #2c2c2c;
      }
    }
    .btn-filter {
      margin-top: 40px;
      height: 50px;
      width: 100%;
    }
  }
}
