@use '../../assets/scss/utilities' as *;

.filter {
  min-width: 260px;
  width: 25%;
  padding: 29px 16px 43px;
  height: fit-content;
  &.loading {
    height: 800px;
    @include loading-skeleton-radial(100%, 100%, 0px, #b3b1b1, #ebebeb, 2s);
  }
  &.filter-in-desktop {
    @include mq(768px, max) {
      display: none;
    }
  }
  @include mq(768px, max) {
    border: none;
    width: 100%;
    padding: 0;
  }

  .category-title {
    font-size: 40px;
    line-height: 43px;
    font-weight: 600;
    color: #000000;
    font-family: 'Modern.20';
    margin-bottom: 40px;
    @include mq(768px, max) {
      display: none;
    }
  }
  .filter-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #000000;
    @include mq(768px, max) {
      display: none;
    }
  }
  .filter-content {
    padding-top: 43px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include mq(768px, max) {
      padding-top: 0px;
      gap: 10px;
    }
    & > .filter-content-item {
      &.has-padding-left {
        padding-left: 8px;
        border-bottom: 1px solid;
      }
    }
  }
}
