@use '../../../assets/scss/utilities' as *;

.modal-review-wrapper {
  padding: 52px 65px;
  position: relative;
  width: 613px;
  height: 745px;
  max-height: 95vh;
  overflow-y: auto;
  @include mq(768px, max) {
    padding: 37px 18.5px;
    width: 90vw;
    max-height: 90vh;
  }

  & > .close {
    position: absolute;
    top: 20px;
    right: 22px;
    @include mq(768px, max) {
      top: 9px;
      right: 13px;
    }
  }
  .modal-header {
    border-bottom: 1px solid #ededed;
    padding-bottom: 31px;
    @include mq(768px, max) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding-bottom: 18.5px;
    }
    & > .modal-title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      color: #2c2c2c;
      text-transform: uppercase;
      @include mq(768px, max) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    & > .product-name {
      font-size: 26px;
      line-height: 32px;
      color: #3e3e3e;
      @include mq(768px, max) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  & > .modal-content {
    padding: 38px 0 0;
    display: flex;
    flex-direction: column;
    gap: 18px;
    @include mq(768px, max) {
      padding: 26.5px 0 0;

    }
    .review-modal-form {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    .review-modal-btn-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      & > .review-modal-btn {
        width: 255px;
        height: 50px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
      }
    }
  }
}
