.label-withoutcheckbox {
  font: normal normal normal 13px/16px Montserrat;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
  color: #000000;
}

.label-container {
  height: 34px;
  padding: 5px;
}
