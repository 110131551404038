@use '../../assets/scss/utilities' as *;

.sign-up-page-form-item {
  @include mq(500px, min) {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 146px auto;

    // grid-template-rows: repeat(6, 70px);
    gap: 20px 10px;
  }

  & > .sign-up-form-label {
    font-size: 14px;
    display: flex;
    align-items: center;
    @include mq(501px, max) {
      margin-bottom: 9px;
      margin-top: 21px;
    }
  }

  & > .sign-up-form-item {
    // display: flex;
    // align-items: center;
    font-size: 14px;
    span {
      font-size: 12px;
      color: #9e9e9e;
    }

    & > .form-input {
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #a7a7a7;
      width: 100% !important;
      height: 45px;
      @include mq(501px, max) {
        height: 45px;
      }
      @include mq(600px, min) {
        width: 75% !important;
      }
      @include mq(899px, min) {
        width: 100% !important;
      }
    }
    & > .error-message {
      font-size: 12px;
      color: red;
      margin-top: 2px;
    }
  }
}

.footer-section {
  span {
    font-size: 10px;
    color: #9e9e9e;
  }
}
.required-items {
  font-size: 10px;
  margin-top: 15px;
  margin-left: 5px;
  margin-bottom: 52px;
  @include mq(500px, min) {
    margin-left: 200px;
    margin-bottom: 49px;
  }
}

.create-btn-bid {
  margin-top: 10px;

  @include mq(500px, min) {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    margin-top: 10px;
    gap: 10px;
  }
  & > .create-btn {
    height: 45px;
    width: 100%;
    @include mq(500px, min) {
      width: 280px;
      margin-top: 20px;
    }
  }
}
