@use '../../../assets/scss/utilities' as *;

.sign-up-page-form-item {
  @include mq(500px, min) {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 146px auto;
  
    // grid-template-rows: repeat(6, 70px);
    gap: 20px 10px;
  }

  & > .sign-up-form-label {
    font-size: 14px;
    display: flex;
    align-items: center;
    @include mq(501px, max) {
      margin-bottom: 9px;
      margin-top: 21px;
    }
  }

  & > .sign-up-form-item {
    // display: flex;
    // align-items: center;
    & > .form-radio-group {
      display: flex;
      align-items: center;
      width: 100% !important;
      height: 41px;
      @include mq(600px, min) {
        width: 75% !important;
      }
      @include mq(899px, min) {
        width: 100% !important;
      }

      & > .radio-item {
        width: 30%;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }

    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      width: 17px;
      height: 17px;
      border: 1px solid #707070;
      border-radius: 50%;
      margin-right: 13px;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      cursor: pointer;
    }

    input[type='radio']::before {
      background-color: #b0903d;
      box-shadow: inset 0 0 0 2px #fff;
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      transform: scale(0);
    }

    input[type='radio']:checked::before {
      transform: scale(1);
    }

    input[type='radio']:focus {
      background-color: #b0903d;
      box-shadow: inset 0 0 0 2px #fff;
    }

    & > .form-input {
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #a7a7a7;
      width: 100% !important;
      height: 45px;
      @include mq(501px, max) {
        height: 45px;
      }
      @include mq(600px, min) {
        width: 75% !important;
      }
      @include mq(899px, min) {
        width: 100% !important;
      }
    }
    & > .error-message {
      font-size: 12px;
      color: red;
      margin-top: 2px;
    }
  }
}

.required-items {
  font-size: 10px;
  margin-top: 15px;
  margin-left: 5px;
  margin-bottom: 52px;
  @include mq(500px, min) {
    margin-left: 200px;
    margin-bottom: 49px;
  }
}

.create-btn-wrapper {
  margin-top: 10px;
  @include mq(500px, min) {
    display: flex;
    justify-content: end;
    margin-bottom: 60px;
  }
  & > .create-btn {
    height: 45px;
    width: 100%;
    @include mq(500px, min) {
      width: 280px;
      margin-top: 20px;
    }
  }
}
