@use '../../assets/scss/utilities' as *;

.price-input-range {
  width: 96%;
  padding-bottom: 20px;
  @include mq(768px, max) {
    width: 102%;
  }
  .input-range {
    & > .input-range__label {
      display: none;
    }
    & > .input-range__track {
      background: #ececec;
      height: 4px;
      @include mq(768px, max) {
        height: 6px;
      }

      & > .input-range__track--active {
        background: #ececec;
        height: 4px;
        @include mq(768px, max) {
          height: 6px;
        }
      }
      .input-range__slider {
        background: #3e3e3e;
        border: 1px solid #3e3e3e;
        border-radius: unset;
        margin-left: 0rem;
        width: 4px;
        height: 16px;
        @include mq(768px, max) {
          width: 5px;
          height: 23px;
          margin-top: -0.9rem;
        }
      }
      .input-range__label--value {
        position: absolute;
        bottom: -1.8rem;
        top: unset;
        .input-range__label-container {
          display: none;
        }
      }
    }
  }
  .range {
    padding-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > p {
      font-size: 12px;
      line-height: 15px;
      color: #3e3e3e;
    }
  }
}
