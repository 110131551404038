@use './../../../assets/scss/utilities/mixins' as *;

.basket-modal-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @include mq(415px, max) {
    width: 100vw;
  }
  .basket-modal-top {
    padding: 40px 23px 34px 23px;
    @include mq(768px, max) {
      padding: 44px 27px 35px 10px;
      overflow-y: auto;
    }
    .modal-header {
      display: flex;
      padding-left: 15px;

      justify-content: space-between;
      padding-bottom: 29.5px;
      @include mq(768px, max) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        padding-left: 15px;
      }
      .close {
        justify-self: flex-end;
      }
      .modal-title {
        font-size: 24px;
        line-height: 26px;
        font-weight: 400;
        color: #2c2c2c;
        font-family: 'Modern20' !important;
      }
    }
    .free-shipping {
      margin: 10px 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      @include mq(768px, max) {
        margin: 8px 20px;
        gap: 5px;
      }
      .shipping-icon {
        width: 40px;
        min-width: 40px;
        height: 36px;
        @include mq(768px, max) {
          width: 34px;
          min-width: 34px;
          height: 34px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .free-shipping-content {
        @include mq(768px, max) {
          padding-left: 15px;
        }
        & > .title {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: #2c2c2c;
        }
        & > .description {
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0px;
          color: #2c2c2c;
          @include mq(768px, max) {
            line-height: 13px;
            max-width: 170px;
          }
        }
      }
    }
    .modal-content {
      padding: 30px 0;
      display: flex;
      padding-left: 20px;
      flex-direction: column;
      gap: 14px;
      @include mq(768px, max) {
        padding: 15px 0 44px 10px;
        gap: 33px;
      }
    }
  }
  .basket-modal-bottom {
    .basket-bottom__wrapper {
      padding: 10px 43px 10px 10px;
      @include mq(768px, max) {
        position: sticky;
        bottom: -1px;
        right: 0;
        left: 0;
        width: 100%;

        z-index: 5;
        padding-right: 45;
      }
      .caption {
        font-size: 13px;
        // line-height: 30px;

        color: #2c2c2c;
        padding-left: 15px;
      }
      .sub-total {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 13px;
          line-height: 30px;

          color: #2c2c2c;
          padding-left: 15px;
        }
        .text-description {
          font-size: 12px;
          line-height: 30px;

          color: #a7a7a7;
        }
        .price-total {
          font-size: 16px;
          line-height: 19px;
          font-weight: 800;
          color: #2c2c2c;
        }
      }
      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 9px;
        padding-top: 25px;
        padding-left: 15px;
        button {
          width: 100%;
          height: 43px;
        }
      }
    }

    .basket-bottom__price {
      padding: 20px 43px 34px 10px;
      @include mq(768px, max) {
        position: sticky;
        bottom: -1px;
        right: 0;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: 5;
        box-shadow: 0px -2px 10px #0000000a;
        padding-right: 45;
      }
      .sub-total {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          line-height: 30px;
          font-weight: bold;
          color: #2c2c2c;
          padding-left: 15px;
        }
        .text-description {
          font-size: 12px;
          line-height: 30px;

          color: #a7a7a7;
        }
        .price-total {
          font-size: 16px;
          line-height: 19px;
          font-weight: 800;
          color: #2c2c2c;
        }
      }
      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 9px;
        padding-top: 25px;
        padding-left: 15px;
        button {
          width: 100%;
          height: 43px;
        }
      }
    }

    .basket-bottom__caption {
      padding: 20px 40px 10px 10px;
      @include mq(768px, max) {
        position: sticky;
        bottom: -1px;
        right: 0;
        left: 0;
        width: 100%;
     
        z-index: 5;
   
        padding-right: 45;
      }
      .caption {
        font-size: 11px;
        // line-height: 17px;
        color: #2c2c2c;
        padding-left: 15px;
        padding-top: 10px;

      }
    }
    .basket-bottom__divider {
      padding: 0px 23px 0px 10px;
      @include mq(768px, max) {
        position: sticky;
        bottom: -1px;
        right: 0;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: 5;
        box-shadow: 0px -2px 10px #0000000a;
        padding-right: 45;
      }
    }
  }
}
