@use '../../assets/scss/utilities' as *;

.menu-popover-desktop {
  padding-top: 5px;
  padding-bottom: 15px;
  &:hover > .title-first-level {
    font-weight: 700;
  }
  .title-first-level {
    font-size: 13px;
    line-height: 16px;
    color: #3e3e3e;
    font-weight: 500;
    cursor: pointer;
  }

  .title-first-level::first-letter {
    text-transform: uppercase;
  }
  .menu-modal {
    position: absolute;
    top: 40px;
    border-top: 3px solid #b0903d;
    left: 20px;
    right: 0;
    z-index: 10;
    width: calc(100% - 75px);
    background-color: white;
    box-shadow: 0px 3px 6px #00000017;
    padding: 40px 27px 40px 38px;
    height: 400px;
    visibility: hidden;
    padding: 40px;
    opacity: 0;
    transition: all 0.3s;
    @include mq(950px, max) {
      width: calc(100% - 40px);
    }
    &.hover-menu {
      opacity: 1;
      visibility: visible;
    }
    .menu-modal-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;
      @include mq(768px, min) {
        gap: 20px;
      }

      @include mq(1024px, min) {
        gap: 50px;
        padding: 20px 20px 0px 60px;
      }

      & > div:last-child {
        border: none;
      }
      &.with-image {
        .group-menu-desktop:nth-child(3) {
          border: none;
        }
      }

      p {
        font-size: 30px;
        line-height: 31px;
        font-family: 'Modern20';
        margin-bottom: 20px;
        & > .title {
          font-size: 30px;
          line-height: 31px;
          font-family: 'Modern20';
          margin-bottom: 20px;
        }
        & > .list {
          column-count: 4;
          padding-right: 100px;
          margin-top: 20px;
          -webkit-column-count: 2;
          -moz-column-count: 2;
          column-gap: 80px;
          // column-rule: 1px solid #e5e5e5;

          & > .list-item {
            color: #3e3e3e;
            break-inside: avoid;
            font-size: 14px;
            line-height: 37px;
            font-weight: 500;
          }
        }
      }

      img {
        height: 95%;
        cursor: pointer;
        @include mq(950px, max) {
          width: 210px;
        }
        @include mq(880px, max) {
          width: 150px;
          object-fit: fill;
        }
      }
    }

    .menu-modal-brands {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;
      @include mq(768px, min) {
        gap: 20px;
      }

      @include mq(1024px, min) {
        gap: 50px;
        padding: 20px 20px 0px 60px;
      }

      & > div:last-child {
        border: none;
      }
      &.with-image {
        .group-menu-desktop:nth-child(3) {
          border: none;
        }
      }

      p {
        font-size: 30px;
        line-height: 31px;
        font-family: 'Modern20';
        margin-bottom: 20px;
        & > .title {
          font-size: 30px;
          line-height: 31px;
          font-family: 'Modern20';
          margin-bottom: 20px;
        }
        & > .list {
          column-count: 4;
          padding-right: 100px;
          margin-top: 20px;
          -webkit-column-count: 2;
          -moz-column-count: 2;
          column-gap: 80px;
          // column-rule: 1px solid #e5e5e5;

          & > .list-item {
            color: #3e3e3e;
            break-inside: avoid;
            font-size: 14px;
            line-height: 37px;
            font-weight: 500;
          }
        }
      }

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        cursor: pointer;
        @include mq(950px, max) {
          width: 210px;
        }
        @include mq(880px, max) {
          width: 150px;
          object-fit: fill;
        }
      }
    }
    & > .brand-list-wrapper {
      margin-left: -20px;
      & > .title {
        font-size: 30px;
        line-height: 31px;
        font-family: 'Modern20';
      }
      & > .list {
        column-count: 4;
        padding-right: 20px;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        // column-rule: 1px solid #e5e5e5;

        & > .list-item {
          color: #3e3e3e;
          break-inside: avoid;
          font-size: 13px;
          line-height: 37px;
          font-weight: 400;
          margin-left: 20px;
        }
      }
    }
  }
  .brand-modal {
    position: absolute;
    top: 40px;
    border-top: 3px solid #b0903d;
    left: 20px;
    right: 0;
    z-index: 10;
    width: calc(100% - 75px);
    background-color: white;
    box-shadow: 0px 3px 6px #00000017;
    padding: 40px 27px 40px 38px;
    height: 470px;
    visibility: hidden;
    padding: 40px;
    opacity: 0;
    transition: all 0.3s;
    @include mq(950px, max) {
      width: calc(100% - 40px);
    }
    &.hover-menu {
      opacity: 1;
      visibility: visible;
    }
    .menu-modal-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      @include mq(768px, min) {
        gap: 20px;
      }

      @include mq(1024px, min) {
        gap: 20px;
        padding: 20px 20px 0px 60px;
      }

      & > div:last-child {
        border: none;
      }
      &.with-image {
        .group-menu-desktop:nth-child(3) {
          border: none;
        }
      }

      p {
        font-size: 30px;
        line-height: 31px;
        font-family: 'Modern20';
        margin-bottom: 20px;
        & > .title {
          font-size: 30px;
          line-height: 31px;
          font-family: 'Modern20';
          margin-bottom: 20px;
        }
        & > .list {
          column-count: 4;
          padding-right: 100px;
          margin-top: 20px;
          -webkit-column-count: 3;
          -moz-column-count: 3;
          column-gap: 80px;
          // column-rule: 1px solid #e5e5e5;

          & > .list-item {
            color: #3e3e3e;
            width: 110px;
            break-inside: avoid;
            font-size: 14px;
            line-height: 37px;
            font-weight: 500;
          }
        }
      }

      img {
        margin-top: 20px;
        height: 280px;
        cursor: pointer;

        @include mq(950px, max) {
          width: 210px;
        }
        @include mq(880px, max) {
          width: 150px;
          object-fit: fill;
        }
      }
    }

    .menu-modal-brands {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;
      @include mq(768px, min) {
        gap: 20px;
      }

      @include mq(1024px, min) {
        gap: 50px;
        padding: 20px 20px 0px 60px;
      }

      & > div:last-child {
        border: none;
      }
      &.with-image {
        .group-menu-desktop:nth-child(3) {
          border: none;
        }
      }

      p {
        font-size: 30px;
        line-height: 31px;
        font-family: 'Modern20';
        margin-bottom: 20px;
        & > .title {
          font-size: 30px;
          line-height: 31px;
          font-family: 'Modern20';
          margin-bottom: 20px;
        }
        & > .list {
          column-count: 4;
          padding-right: 100px;
          margin-top: 20px;
          -webkit-column-count: 2;
          -moz-column-count: 2;
          column-gap: 80px;
          // column-rule: 1px solid #e5e5e5;

          & > .list-item {
            color: #3e3e3e;
            break-inside: avoid;
            font-size: 14px;
            line-height: 37px;
            font-weight: 500;
          }
        }
      }

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        cursor: pointer;
        @include mq(950px, max) {
          width: 210px;
        }
        @include mq(880px, max) {
          width: 150px;
          object-fit: fill;
        }
      }
    }
    & > .brand-list-wrapper {
      margin-left: -20px;
      & > .title {
        font-size: 30px;
        line-height: 31px;
        font-family: 'Modern20';
      }
      & > .list {
        column-count: 4;
        padding-right: 20px;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        // column-rule: 1px solid #e5e5e5;

        & > .list-item {
          color: #3e3e3e;
          break-inside: avoid;
          font-size: 13px;
          line-height: 37px;
          font-weight: 400;
          margin-left: 20px;
        }
      }
    }
  }
}
