.preview-product-sheet-modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: white;
  box-shadow: 3px 6px 20px #00000029;
  border: none;
  position: relative;
  & > .close {
    position: absolute;
    top: 20px;
    right: 22px;
    z-index: 1;
  }
  .current-product-wrapper {
    height: 85vh;
    & > .img-product {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & > .thumbnail-wrapper {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    & > .thumbnail {
      width: 100%;
      height: 15vh;
      padding: 10px 0;
      cursor: pointer;
      position: relative;
      z-index: 2;

      & > .keen-slider__slide.active > .product-thumbnail > img {
        border: 1px solid black;
        padding: 4px;
        object-fit: contain;
      }
      .product-thumbnail {
        height: 100%;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          aspect-ratio: 1/1;
        }
      }
    }
    & > .arrow-wrapper {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: calc(100% + 100px);
      left: -50px;
      & > .preview-image,
      & > .next-image {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.arrow--disabled {
          visibility: hidden;
        }
      }
    }
  }
}
