.group-checkbox-filter {
    padding-bottom: 20px;
    & > .show-more {
      text-decoration: underline;
      font: normal normal 600 14px/18px Montserrat;
      color: #3e3e3e;
      padding-top: 18px;
      cursor: pointer;
    }
  }
  