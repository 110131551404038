@use './../../assets/scss/utilities/mixins' as *;

.product-search-card {
  display: flex;
  gap: 18px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  @include mq(768px, max) {
    gap: 13px;
  }
  .img-wrapper {
    min-width: 73px;
    width: 73px;
    height: 73px;
    @include mq(768px, max) {
      width: 71px;
      height: 76px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      @include mq(768px, max) {
        border-radius: 5px;
      }
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    & > .title {
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #3e3e3e;
      text-transform: uppercase;
      @include mq(768px, max) {
        font-size: 12px;
        line-height: 15px;
      }
    }
    & > .description {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #3e3e3e;
      text-transform: uppercase;
      @include mq(768px, max) {
        font-size: 13.5px;
      }
    }
    & > .price {
      font-size: 15px;
      line-height: 19px;
      font-weight: 800;
      color: #3e3e3e;
      @include mq(768px, max) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
