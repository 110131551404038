.custom-textarea {
  margin: 10px 0;
  & > .textarea-label {
    font-size: 13px;
    line-height: 16px;
    color: #3e3e3e;
  }
  & > textarea {
    margin: 9px 0 5px;

    width: 100%;
    box-shadow: 0 0 0 0.5px #707070;
    outline: none;
    border: none;
    padding: 9px 10px;
    height: 79px;
    overflow-y: auto;
    resize: none;

    &::placeholder {
      font-size: 13px;
      line-height: 16px;
      color: #cccccc;
    }

    &:focus {
      box-shadow: 0 0 0 0.6px black;
    }
  }
  & > .textarea-error {
    font-size: 11px;
    line-height: 15px;
    color: #cc0d0d;
  }
}
