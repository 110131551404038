@mixin loading-skeleton-radial(
  $width,
  $height,
  $borderRadius,
  $color,
  $highlight-color,
  $animation-duration
) {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $borderRadius;
    width: $width;
    height: $height;
    background-image: radial-gradient(circle, $color, $highlight-color, $color);
    background-size: 200% 200%;
    animation: loading-skeleton-radial-animation $animation-duration infinite;
  }
  @keyframes loading-skeleton-radial-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
