.direct-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 19px 15px 32px;
  border-bottom: 1px solid #525151;
  background-color: black;
  .title {
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      width: 13px;
      height: 16px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: white;
      &.special {
        color: #cc0d0d;
        font-weight: bold;
      }
      &.regular {
        font-weight: 400;
      }
    }
  }
  .right {
    width: 13px;
    height: 16px;
  }
}
