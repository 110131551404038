.custom-group-radio {
  margin: 10px 0;
  & > .custom-group-radio-label {
    font-size: 13px;
    line-height: 16px;
    color: #3e3e3e;
    padding-bottom: 12px;
  }
  span.label-checkbox {
    font-size: 14px;
    line-height: 18px;
    color: #2c2c2c;
  }
}
