.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999999;
  & > .loader-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
    animation: scale 1.5s infinite linear;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
