/* ------ colors ----------
-----------------------------*/

//white

$pure_white: #ffffff;

$white1: #dddddd;
$white2: #ebebf3;

//black

$pure_black: #000000;

$lighter_black: #0d0e0f;

//gray

$gray: gray;

$darker_gray: #6c757d;
$darker_gray1: #757575;
$darker_gray2: #7e7e7e;
$darker_gray3: #636161;
$darker_gray4: #495057;
$darker_gray5: #555;
$darker_gray6: #494949;
$darker_gray7: #363636;
$darker_gray8: #bdaeae1f;

$lignter_gray: #808295;
$lignter_gray1: #a2a2a7;
$lignter_gray2: #cdd2d7;
$lignter_gray3: #fcfcfc;
$lighter_black4: #eaeaea;
$lighter_black5: #bdbdbd;

//blue

$darker_blue: #3870a3;
$darker_blue1: #16739c;
$darker_blue2: #18698d;
$darker_blue3: #18699c;
$darker_blue4: #18699cc7;
$darker_blue5: #0c5677;
$darker_blue6: #2ba7df29;
$darker_blue7: #a2a2bb;
$darker_blue8: #7c8494;

$lighter_blue: #d5edf9;
$lighter_blue1: #f1f1f7;
$lighter_blue2: #f2f4fd;
$lighter_blue3: #e2e2e4;
$lighter_blue4: #edf2f5;
$lighter_blue5: #e8eafc;
$lighter_blue6: #c6cbd4;
$lighter_blue7: #b7becd;
$lighter_blue8: #d6e0ec;
$lighter_blue9: #b8b8c9;
$lighter_blue10: #ababb0;
$lighter_blue11: #a9a9ae;
$lighter_blue12: #9a9aab;
$lighter_blue13: #838eab;
$lighter_blue14: #e3e3e4;
$lighter_blue15: #f8f8fd;

$summer_sky: #2ba7df;

$sky_blue: #248bb9;

$darker_sky_blue: #2490f1;
$darker_sky_blue1: #1f89f1;
$darker_sky_blue2: #1e87f0;
$darker_sky_blue3: #007bff;

$lighter_sky_blue: #3bb4f9;
$lighter_sky_blue1: #40ccfe;
$lighter_sky_blue2: #3db7f9;
$lighter_sky_blue3: #4d96db;
$lighter_sky_blue4: #34c0ff;
$lighter_sky_blue5: #40bcf9;
$lighter_sky_blue6: #80cff3;
$lighter_sky_blue7: #a9d6ff;
$lighter_sky_blue8: #b1e4f9;
$lighter_sky_blue9: #d8eeff;
$lighter_sky_blue10: #ebf0f3;
$lighter_sky_blue11: #daeef7;
$lighter_sky_blue12: #f8f9fa;

//yellow

$lighter_yellow: #f9ebd5;
$lighter_yellow1: #edc454;

//orange

$orange: #ff9800;

$lighter-orange: #fbeadf;

$darker_orange: #ec5542;
$darker_orange1: #e94a14;
$darker_orange2: #f44336;

//purple

$purple: #4a3c98;

//green

$light_green: #0ca157;
$lighter_green: #40a070;
$lighter_green1: #46ca88;
$lighter_green2: #50bf88;
$lighter_green3: #dff4e9;

//pink
$dark_pink: #f50057;

//red

$red: red;

$darken_red: #ed545412;

$darker_red: #f02849;
$darker_red1: #d72c34;

$lighter_red: #dc3545;
$lighter_red1: #d25050;
$lighter_red2: #f45158;
$lighter_red3: #ed5454;
$lighter_red4: #da5e64;
$lighter_red5: #fe726e;
$lighter_red6: #efd2d4;
$lighter_red7: #c5c5c5;
$lighter_red8: #c4c4c4;
$lighter_red9: #ffffffc2;
$lighter_red10: #ffeaea;
