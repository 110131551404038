@use '../../assets/scss/utilities' as *;

.button {
  outline: none;
  border: none;
  color: #ffffff;
  line-height: 18px;
  font-weight: normal;
  font-size: 14px;
  background: #1C1C1C;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
  border: 1px solid transparent;
  &.loading-skeleton {
    @include loading-skeleton-radial(100%, 100%, 0px, #b3b1b1, #ebebeb, 2s);
    border: none;
  }
  &:hover {
    border: 1px solid black;
    background-color: white;
    color: black;
    .cubes > .bounce > div {
      background-color:#B0903D ;
    }
  }

  &:disabled {
    background-color: #b3b1b1;
    color: #ebebeb;
    border: 1px solid #b3b1b1;
    cursor: not-allowed;
  }
}

.medium {
  width: 131px;
  height: 45px;
}
.small {
  width: 131px;
  height: 45px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #ebe5e5;
  &:hover {
    color: #cc0d0d;
  }
  @include mq(768px, max) {
    font-size: 8px;
    line-height: 10px;
    width: 78px;
    height: 27px;
  }
}
.card-button {
  width: 94%;
  margin: 0 3%;
  line-height: 15px;
  font-weight: 500;
  font-size: 12px;
  height: 35px;
  @include mq(768px, max) {
    line-height: 14px;
    font-size: 10px;
    height: 25px;
  }
}
.white {
  width: 100%;
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  height: 50px;
}
.black {
  width: 50%;
  background: white;
  border: 1px solid #3e3e3e;
  color: #3e3e3e;
  & > img,
  & > span {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background: #3e3e3e;
    border: 1px solid #3e3e3e;
    & > span {
      color: white;
    }
    & > img {
      filter: brightness(0) invert(1);
    }
  }
}

.background-black {
  width: 50%;
  background: black;
  color: white;
  height: 41px;
  
  &:hover {
    background: white;
    border: 1px solid #3e3e3e;
    color: #3e3e3e;
    .cubes > .bounce > div {
      background-color: #3e3e3e;
    }
  }
}


.background-orange {
  width: 100%;
  background: #FFF5F0B2;
  color: #1C1C1C;
  height: 41px;
  width: 116px;
  font-weight: 400;
  &:hover {
    background: white;
    border: 1px solid #3e3e3e;
    color: #3e3e3e;
    .cubes > .bounce > div {
      background-color: #3e3e3e;
    }
  }
}
.auth-sign-in-btn-section{

  width: 100%;
  background: #B0903D;
  color: #1C1C1C;
  height: 45px;
  width: 161px;
  font-weight: 400;
}
.auth-sign-in-btn-new{

  width: 100%;
  background: #B0903D;
  color: white;
  height: 45px;
  width: 161px;
  font-weight: 400;
}
.auth-sign-in-btn{

  width: 100%;
  background: #B0903D;
  color: #1C1C1C;
  height: 41px;
  width: 116px;
  font-weight: 400;
}
.disabled-button {
  background-color: $lighter_black4;
  color: $lignter_gray;
  border: 2px solid $lignter_gray;
  cursor: default;
}

.hidden-btn {
  visibility: hidden !important;
}

.loading-add-user-btn {
  background: $white2 !important;
  color: $pure_black;
  border: 2px solid $lighter_blue10 !important;
  cursor: default;
}

.loading-disable-user-btn {
  background: $white2;
  color: $pure_black;
  cursor: default;
}
