@use '../../../assets/scss/utilities' as *;

.delete-address-wrapper {
  padding: 32px 46px;
  position: relative;
  width: var(--modalWidth);
  min-height: var(--modalHeight);
  max-height: 95vh;
  @include mq(786px, max) {
    padding: 37px 18.5px;
    width: 80vw;
    max-height: 80vh;
  }

  & > .close {
    position: absolute;
    top: 5px;
    right: 9px;
  }

  & > .modal-content {
    & > .success-message {
      font: normal normal 600 14px/21px Montserrat;
      color: #2daf54;
    }
    & > .error-message {
      font: normal normal 600 14px/21px Montserrat;
      color: #cc0d0d;
    }
    & > .message-text {
      font: normal normal normal 12px/21px Montserrat;
      color: #3e3e3e;
      margin-top: 12px;
    }
  }

  & > .action-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    & > .action-btn {
      width: 60px;
      height: 30px;
    }
    & > .cancel-btn {
      background: #3e3e3e !important;
      border: 1 solid #3e3e3e !important;
    }
    & > .cancel-btn:hover {
      color: #3e3e3e !important;
      background-color: #fafafa !important;
      border: 1px solid #3e3e3e !important;
    }
  }
}
