@use '../../../assets/scss/utilities' as *;

.custom-input {
  margin: 10px 0;
  & > .input-label {
    font-size: 13px;
    line-height: 16px;
    color: #3e3e3e;
  }
  & > .input-wrapper {
    & > input {
      margin: 9px 0 5px;
      background-color: #FFF5F0B2;
      width: 100%;
      height: 41px;
      box-shadow: 0 0 0 0.5px #707070;
      outline: none;
      border: 1px solid #707070;
      padding: 0 10px;
      &::placeholder {
        font-size: 13px;
        line-height: 16px;
        color: #cccccc;
      }
      &:focus {
        box-shadow: 0 0 0 0.6px black;
      }

      /*  Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
      }
      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield !important;
      }
    }
    & > .input-error {
      font-size: 11px;
      line-height: 15px;
      color: #cc0d0d;
    }
  }
}
