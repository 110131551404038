.collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-collapse {
    font-weight: 500;
    font-size: 13px;
    line-height: 42px;
    color: white;
    text-transform: capitalize;
    &.active {
      font-weight: bold;
    }
  }

  .btn-collapse {
    .hyphen {
      width: 19px;
      height: 10px;
      fill: #ffff;
    }
    .plus {
      width: 19px;
      height: 10px;
      fill: #ffff;
    }
  }
  &.in-filter {
    padding-bottom: 15px;

    .title-collapse {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #3e3e3e;
      text-transform: uppercase;
      &.active {
        font-weight: 600;
      }
    }
  }
  &.nested-collapse .title-collapse {
    font-weight: 400;
    font-size: 12px;
    line-height: 45px;
    color: #737171;
    text-transform: capitalize;
    &.active {
      color: #2c2c2c;
      font-weight: 500;
    }
  }
}

.nested-nested-collapse {
  font-size: 12px;
  line-height: 70px;
  color: white;
  text-transform: capitalize;
  font-weight: 400;
}
