@use '../../../assets/scss/utilities' as *;

.modal-review-wrapper {
  padding: 24px 30px 25px 24px;
  border-radius: 5px;
  position: relative;
  width: 870px;
  height: auto;
  max-height: auto;
  overflow-y: auto;
  @include mq(768px, max) {
    padding: 37px 18.5px;
    width: 90vw;
    max-height: 90vh;
  }

  & > .modal-add-title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #020202;
    margin-bottom: 15px;
    text-transform: uppercase;
    @include mq(768px, max) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  & > .close {
    position: absolute;
    top: 20px;
    right: 22px;
    @include mq(768px, max) {
      top: 9px;
      right: 13px;
    }
  }
  .modal-header {
    border-bottom: 1px solid #ededed;
    padding-bottom: 31px;
    @include mq(768px, max) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding-bottom: 18.5px;
    }
    & > .modal-title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 800;
      color: #2c2c2c;
      text-transform: uppercase;
      @include mq(768px, max) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    & > .product-name {
      font-size: 26px;
      line-height: 32px;
      color: #3e3e3e;
      @include mq(768px, max) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  & > .modal-content {
    padding: 25px 0 0;
    display: flex;
    flex-direction: column;
    gap: 0px;
    @include mq(768px, max) {
      padding: 26.5px 0 0;
      gap: 10px;
    }
    & > .added-to-card-container {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 100%;
      width: 100%;
      padding: 10px 0 18px;

      @include mq(1000px, max) {
        padding: 10px 0 14px;
      }
      @include mq(768px, max) {
        max-width: unset;
        width: 100%;
        gap: 10px;
        flex-direction: column;
      }

      & .basket-btn {
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        color: black;
        font-weight: 500;
        background-color: white;
        &.selected {
          border: 3px solid #b0903d;
        }
        &::first-letter {
          text-transform: uppercase;
        }

        & img {
          width: 60px; /* Adjust the width as needed */
          height: auto; /* Maintain aspect ratio */
        }
        & svg {
          scale: 1.3;
          aspect-ratio: 3 / 2;
        }
        & .basket-btn:disabled {
          background-color: white;
        }
      }
      span {
        font-size: 14px;
      }
      & > .product-counter-sheet {
        height: 50px;
        width: 132px;
        @include mq(768px, max) {
          width: 117px;
          height: 44px;
        }
      }

      & > .added-to-card-btn {
        flex: 1;
        height: 50px;
        font-size: 14px;
        font-weight: 500;
        @include mq(768px, max) {
          height: 44px;
          font-size: 12px;
        }
      }

      & > .bid-btn {
        max-width: 100%;
        width: 50%;
        height: 50px;
        font-size: 14px;
        font-weight: 500;
        @include mq(768px, max) {
          max-width: unset;
          height: 44px;
          font-size: 12px;
        }
      }
    }

    .review-modal-form {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    .review-modal-btn-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      & > .review-modal-btn {
        width: 255px;
        height: 50px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
      }
    }
  }
}
