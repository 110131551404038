@use '../../assets/scss/utilities' as *;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.header {
  position: relative;
  .top-header {
    background-color: #b0903d;
    padding: 9px 0;
    color: white;
    @include mq(991px, max) {
      display: none;
    }
    .top-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .social-media {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .client-service {
        display: flex;
        align-items: center;
        gap: 4px;
        span:first-child {
          font-size: 13px;
          line-height: 16px;

          color: black;
        }
        // span:last-child {
        //   font-size: 13px;
        //   line-height: 16px;
        //   font-weight: bold;
        //   color: #ffffff;
        // }
      }
      .navigation {
        display: flex;
        gap: 20px;
        p {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
  .favoris-list {
    display: block;
    @include mq(991px, max) {
      display: none;
    }
  }
  .middle-header {
    background-color: white;
    padding-top: 10px;
    position: relative;
    @include mq(991px, max) {
      padding: 11px 0;
      box-shadow: 2px 4px 10px #0000000f;
    }
    .search-result-wrapper {
      position: absolute;
      top: 110px;
      left: 0;
      right: 0;
      z-index: 10;
      // background-color: rgba(0, 0, 0, 0.5);
      height: 100vh;
    }
    .middle-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 140px;

      @include mq(991px, max) {
        flex-wrap: wrap;
        gap: 18px;
      }
      .logo-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        @include mq(991px, max) {
          gap: 20px;
        }
        .logo-img {
          position: relative;
          right: 14px;
          max-width: 172px;
          max-height: 60px px;
          @include mq(991px, max) {
            position: unset;
            width: 120px;
            height: 38px;
          }
        }
      }
      .search {
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        z-index: 12;

        @include mq(991px, max) {
          order: 3;
          min-width: 50px;
        }
        .search-result {
          position: absolute;
          top: 40px;
          left: 0;
          right: 0;
          z-index: 11;
          background-color: white;
          width: 100%;
          min-height: 100px;
          border: 1px solid #e0e0e0;
          padding: 23px 49px 23px 54px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          &.active {
            visibility: visible;
            opacity: 1;
          }
          @include mq(1330px, max) {
            min-width: 450px;
          }
          @include mq(1100px, max) {
            min-width: 325px;
          }
          @include mq(991px, max) {
            left: 0;
            width: 100%;
          }
          & .voir-tous {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
          }
          .search-result__cards {
            display: flex;
            flex-direction: column;
            gap: 18px;
            padding: 20px 0;
            max-height: 50vh;
            overflow-y: auto;
          }
        }
        input[type='search'] {
          appearance: none;
          width: 100%;
          height: 35px;
          outline: none;
          border: none;
          border: 1px solid #d9d9d9;
          padding-left: 26px;
          @include mq(600px, max) {
            width: 100%;
          }
          &::placeholder {
            font-size: 13px;
            line-height: 16px;
            color: #ccc3c3;
            font-style: italic;
          }
          &::-webkit-search-cancel-button {
            position: absolute;
            right: 27px;
            -webkit-appearance: none;
            height: 18px;
            width: 18px;
            border-radius: 10px;
            background: url('./../../assets/img/icons/close.svg');
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
          }
        }
        .search-icon {
          position: absolute;
          right: 15px; /* Adjust this value to suit your design */
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          @include mq(600px, max) {
            right: 15px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .actions-wrapper__middle-header {
        min-width: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;
        @include mq(991px, max) {
          min-width: unset;
          justify-content: flex-start;
          gap: 17px;
        }

        button {
          cursor: pointer;
          border: none;
          outline: none;
          background-color: white;
        }
        .action__middle-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 3px;
          cursor: pointer;
          .shopping-bag-wrapper {
            position: relative;
            display: flex;
            .count {
              position: absolute;
              left: 14px;
              top: 4px;
              width: 14px;
              height: 14px;
              background: #b0903d;
              font-size: 8px;
              line-height: 8px;
              color: #ffffff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              @include mq(991px, max) {
                width: 12px;
                height: 12px;
                font-size: 7px;
              }
            }
          }
          p {
            font-size: 13px;
            line-height: 15px;
            color: #3e3e3e;
            font-weight: 500;
            @include mq(991px, max) {
              display: none;
            }
          }
        }
      }
    }
  }
  .bottom-header {
    background: white;
    border-bottom: 1px solid #e9e9e9;
    @include mq(991px, max) {
      display: none;
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 40px;
      // padding-left: 200px;
      padding-right: 20px;
      @include mq(950px, max) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .link {
        font-size: 13px;
        line-height: 16px;
        color: #3e3e3e;
        font-weight: 500;
      }
      .new {
        font-size: 13px;
        line-height: 16px;
        color: #3e3e3e;
        text-transform: uppercase;
        font-weight: 400;
      }
      .tips {
        font-size: 13px;
        font-weight: bold;
        line-height: 16px;
        letter-spacing: 0px;
        color: #3e3e3e;
      }
      .promo {
        background: #cc0d0d;
        padding: 8px 17px;
        color: white;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
}

.menu-item-header-profile {
  &:hover {
    background-color: transparent !important;
  }
  &:active {
    background-color: transparent !important;
  }
  display: flex;
  gap: 17px;
  padding: 14px !important;

  img {
    width: 14px;
    height: 15px;
    object-fit: contain;
    aspect-ratio: 3/2;
  }
  p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: #3e3e3e;
  }
}

.social-icons {
  width: 12px;
  height: 15px;
}
